import { Component, computed, inject } from '@angular/core';
import { CenterStore } from '../../../stores/center.store';
import { Router } from '@angular/router';
import { CenterService, CenterUserService } from '@malirang/api-client';

@Component({
  selector: 'app-center-page-adapter',
  template: '',
})
export class CenterPageAdapter {
  readonly centerService = inject(CenterService);
  readonly centerUserService = inject(CenterUserService);
  readonly store = inject(CenterStore);
  readonly router = inject(Router);
  center = this.store.currentCenter;
  centerId = computed(() => this.center()?.id || '');
}
